import React from 'react'
import PropTypes from 'prop-types'

const TwitterFollow = ({ username, showCount }) => (
  <a
    href={`https://twitter.com/${username}?ref_src=twsrc%5Etfw`}
    className="twitter-follow-button"
    data-size="large"
    data-dnt="true"
    data-show-count={showCount}
  >
    Follow @{username}
  </a>
)

TwitterFollow.propTypes = {
  username: PropTypes.string,
  showCount: PropTypes.string,
}
TwitterFollow.defaultProps = {
  showCount: 'false',
}
export default TwitterFollow
