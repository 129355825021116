import React from 'react'

export default () => (
  <section id="newsletter-signup">
    <div className="grid-container">
      <div className="grid-x grid-margin-x align-left h5">
        <div className="cell medium-8">
          <h4>Sign up for our newsletter</h4>
          <p>
            Get an email when new content comes out! Emails are not sold and are
            used only for announcing updates to the site. Expect ~1-2 emails per
            month. Unsubscribe any time.
          </p>
        </div>
        <form
          className="cell medium-4"
          action="https://recipes.us18.list-manage.com/subscribe/post?u=6bac03fc0caa5ea1bea5bc64d&amp;id=fd59c27042"
          method="post"
          noValidate
        >
          <label>
            <strong>Email Address:</strong>
            <input
              type="email"
              name="EMAIL"
              className="email"
              placeholder="email address"
            />
            <div
              style={{
                position: 'absolute',
                left: '-5000px',
              }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_6bac03fc0caa5ea1bea5bc64d_fd59c27042"
                tabIndex="-1"
                value=""
              />
            </div>
          </label>
          <input
            type="submit"
            className="button large warning"
            value="Sign Up"
          />
        </form>
      </div>
    </div>
  </section>
)
