import React from 'react'
import Rss from './rss.svg'

const RssLink = props => (
  <a href="/rss.xml" className="rss">
    <img src={Rss} height="18px" />
    RSS Feed
  </a>
)

export default RssLink
