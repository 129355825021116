import React from 'react'
import Link from 'gatsby-link'

import TwitterFollow from '../twitter-follow'
import RssLink from '../rss-link'

import trabouleLogo from '../../images/traboule-square-160.png'

export default () => (
  <section id="footer" className="align-self-stretch">
    <div className="grid-container">
      <div className="grid-x align-justify h5">
        <div className="auto grow">
          <p>&copy; Traboule Software LLC</p>
          <ul className="menu">
            <li>
              <Link to="/about/">About</Link>
              <RssLink />
            </li>
          </ul>
        </div>
        <div className="cell auto shrink">
          <h2>Stay up to date</h2>
          <p>
            <TwitterFollow username="iosdevrecipes" showCount="true" />
          </p>
          <p>
            <TwitterFollow username="daveisonthego" showCount="true" />
          </p>
          <p>
            <TwitterFollow username="traboulesoft" showCount="true" />
          </p>
        </div>

        <a
          href="https://www.traboulesoftware.com/"
          className="auto shrink align-self-end"
        >
          <img src={trabouleLogo} alt="Traboule Software Logo" />
        </a>
      </div>
    </div>
  </section>
)
