import React from 'react'
import Link from 'gatsby-link'
import Logo from '../../images/Logo.png'
import Logo2 from '../../images/Logo@2x.png'
import Logo3 from '../../images/Logo@3x.png'

import RssLink from '../rss-link'

const Header = ({ siteTitle }) => (
  <header className="">
    <div className="grid-container grid-x grid-x-padding">
      <p className="cell medium-6">
        <Link
          to="/"
          style={{
            textDecoration: 'none',
          }}
          className="header-link"
        >
          <img
            src={Logo}
            srcSet={`${Logo2} 2x, ${Logo3} 3x`}
            alt={siteTitle}
            className="header-logo"
          />
          {siteTitle}
        </Link>
      </p>
      <p className="cell medium-6 align-right byline">
        <Link
          to="/articles/"
          style={{
            textDecoration: 'none',
          }}
          // className="header-link"
        >
          Articles
        </Link>

        <RssLink />
      </p>
    </div>
  </header>
)

export default Header
