import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'

import Header from '../header'
import Footer from '../footer'
import NewsletterSignup from '../newsletter-signup'

import './index.scss'

const JSON_LD_ORGANIZATION = siteUrl => `
[
    {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "iOS Dev Recipes",
    "url": "${siteUrl}",
    "logo": {
        "@type": "ImageObject",
        "url": "https://www.iosdev.recipes/img/logos/logo-1024.png",
        "width": 1024,
        "height": 1024
    },
    "sameAs": [
        "http://www.twitter.com/iosdevrecipes"
    ]
    }
]    
`

const twitterCardMeta = ({ type, title, description, imageURL }) => {
  return [
    { name: 'twitter:card', content: type },
    { name: 'twitter:site', content: '@iosdevrecipes' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: imageURL },
  ]
}

const openGraphMeta = ({ pageURL, ogType, title, description, imageURL }) => {
  return [
    { name: 'og:url', content: pageURL },
    { name: 'og:type', content: ogType },
    { name: 'og:title', content: title },
    { name: 'og:description', content: description },
    { name: 'og:image', content: imageURL },
  ]
}

const pageMeta = (siteURL, path) => {
  const metas = {
    pageURL: `${siteURL}${path}`,
    twitterType: 'summary',
    ogType: 'website',
    title: 'iOS Dev Recipes',
    description:
      'A reference site for iOS developers. ' +
      'Quickly find links to relevant documentation, ' +
      'examples of command line tools, and guides to obscure parts of Xcode and UIKit.',
    imageURL: 'https://www.iosdev.recipes/img/logos/logo-2048.png',
  }

  return twitterCardMeta(metas).concat(openGraphMeta(metas))
}

class DefaultLayout extends Component {
  render() {
    const { siteMetadata, location, children } = this.props

    const path = location.pathname

    return (
      <div className="grid-y">
        <Helmet
          title={siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'A reference site for iOS developers. ' +
                'Quickly find links to relevant documentation, ' +
                'examples of command line tools, and guides to obscure parts of Xcode and UIKit.',
            },
            {
              name: 'apple-mobile-web-app-title',
              content: 'iOSDev.recipes',
            },
          ].concat(pageMeta(siteMetadata.siteUrl, path))}
          link={[
            {
              rel: 'apple-touch-icon',
              href: 'https://www.iosdev.recipes/img/logos/logo-1024.png',
            },
          ]}
          htmlAttributes={{ lang: 'en-US' }}
        >
          <script type="application/ld+json" id="json-ld">
            {JSON_LD_ORGANIZATION(siteMetadata.siteUrl)}
          </script>
        </Helmet>

        <Header siteTitle={siteMetadata.title} />

        <main className="">{children}</main>
        <NewsletterSignup />
        <Footer />
      </div>
    )
  }
}

DefaultLayout.propTypes = {
  childFunc: PropTypes.func,
  siteMetadata: PropTypes.shape({
    title: PropTypes.string,
    siteUrl: PropTypes.string,
  }),
}

export default DefaultLayout
